const SocialData = [
    {
        Id: 0,
        Name: "Email",
        Link: "mailto:salemsalleh@gmail.com",
    },
    {
        Id: 1,
        Name: "Github",
        Link: "https://www.github.com/salaami",
    },
    {
        Id: 2,
        Name: "Linkedin",
        Link: "https://www.linkedin.com/in/salem-salleh-1a4828a8",
    },
    {
        Id: 3,
        Name: "Xing",
        Link: "https://www.xing.com/profile/Salem_Salleh",
    },
]
export { SocialData }
