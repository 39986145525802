const SkillData = [
  {
    Id: 0,
    Level: 90,
    Title: "SQL",
  },
  {
    Id: 1,
    Level: 70,
    Title: "PYTHON",
  },
  {
    Id: 2,
    Level: 70,
    Title: "JSX & CSS",
  },
  {
    Id: 3,
    Level: 70,
    Title: "SHELL",
  },
  {
    Id: 4,
    Level: 60,
    Title: "ELIXIR",
  },
  {
    Id: 5,
    Level: 50,
    Title: "R",
  },
]

export { SkillData }

